import { getImagesCdnUrl } from "@common/utils/env"

export const DOWNLOAD_APP_CARD_LINKS: any = {
	ios: "https://apps.apple.com/in/app/wint-wealth-high-returns-bonds/id6446472037",
	android:
		"https://play.google.com/store/apps/details?id=com.fourdegreewater.wintwealth",
	title: "Download Wint Wealth mobile app",
	qrImage: `${getImagesCdnUrl()}/common/downloadAppQRCode.svg`,
}

export const DOWNLOAD_APP_CARD_ID: any = {
	ios: "DOWNLOAD-IOS_APP",
	android: "DOWNLOAD-ANDROID_APP",
}
