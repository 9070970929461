import { SITE_URLS } from "@common/constants"
import { getUserKycStatus, getUserSebiKycStatus } from "../auth"

export const getRedirectLinkBasedonInvestorStatus = (
	baseUrl: string,
	replacementUrl: string,
) => {
	let urlToRedirectTo = baseUrl

	const kycStatus = getUserKycStatus()
	const sebiKycStatus = getUserSebiKycStatus()

	if (
		kycStatus === "APPROVED" ||
		["REJECTED", "APPROVED", "SUBMITTED"]?.includes(sebiKycStatus as string)
	) {
		urlToRedirectTo = replacementUrl
	}
	return urlToRedirectTo
}

export const getIsBottomNavbarAllowedInPage = (pathname: string): boolean => {
	// here check happens by splitting each item of array by '/' and see if word from pathname is present in that array
	// here we are checking if one true is present out of all items checked from array
	const isUrlAllowed = (keyword: string) =>
		[SITE_URLS.DASHBOARD_V3, SITE_URLS.BONDS, SITE_URLS.FD_HOME]
			.flatMap((item: string) => {
				return item?.split("/").includes(keyword)
			})
			.some(item => item === true)

	// pathname will be having slashes at the prefix and suffix and we will consider the first word of url
	return isUrlAllowed(
		pathname.replace(/^\/([^\\/]*).*$/, "$1").replaceAll("/", ""),
	)
}

/**
 *
 * @param {*} params - accepts an object in the formate {apiKey:value}
 * @return {string}  returns string of required api parameters from the given object.
 */
export const generateParamString = (
	params: Record<string, any> = {},
): string => {
	let paramString = ""
	Object.keys(params).forEach((param, i) => {
		paramString += `${i > 0 ? "&" : "?"}${param}=${params[param]}`
	})
	return paramString
}

/**
 *
 * @param bondNameWithId - bond name with id
 * @returns {number} the bond id from the bond name with id
 */
export const getBondIdFromUrl = (bondNameWithId: string): number => {
	const idIndex = bondNameWithId?.lastIndexOf("-")
	const id = parseInt(bondNameWithId?.slice(idIndex + 1), 10)
	return id
}

export const getSlugFromString = (string: string): string => {
	if (!string) return ""

	return string
		.toLowerCase()
		.trim()
		.normalize("NFD")
		.replace(/[\u0300-\u036f]/g, "")
		.replace(/[^a-z0-9\s-]/g, " ")
		.trim()
		.replace(/[\s-]+/g, "-")
}

export const updateQueryParam = (
	key: string,
	value: string,
	replaceHistory = false,
) => {
	// @ts-ignore
	const url = new URL(window.location)
	url.searchParams.set(key, value)
	if (replaceHistory) {
		window.history.replaceState(null, "", url)
	} else {
		window.history.pushState(null, "", url)
	}
}

export const removeQueryParam = (key: string, replaceHistory = false) => {
	// @ts-ignore
	const url = new URL(window.location)
	url.searchParams.delete(key)
	if (replaceHistory) {
		window.history.replaceState(null, "", url)
	} else {
		window.history.pushState(null, "", url)
	}
}
