import { SITE_URLS } from "@common/constants"
import { User, UserName } from "@common/types/user"
import { getIsUserBondsInvestor } from "@common/utils/auth"
import { removeTrailingSlashFromURL } from "@common/utils/string"
import { DOWNLOAD_APP_CARD_LINKS } from "@common/constants/download_app"
import { WintLogoIcon } from "wint-icons"
import navDashboardIcon from "../assets/navDashboardIcon.json"
import { NAVBAR_ASSETS_URL, NAV_CTA_IDS } from "../constants"
import {
	BottomBarActions,
	DropdownSideBarItem,
	NavbarItem,
	TopNavbarActions,
} from "../types"

type DropDownSideBarItems = DropdownSideBarItem[]

const getDropdownSidebarItems = (
	actions: TopNavbarActions | BottomBarActions,
	successfulReferrals: number = 0,
): DropDownSideBarItems => {
	return [
		{
			icon: `${NAVBAR_ASSETS_URL}orders_icon.svg`,
			label: "Orders",
			link: SITE_URLS.ORDERS,
			isVisible: true,
			id: NAV_CTA_IDS.orders,
		},
		{
			icon: `${NAVBAR_ASSETS_URL}help.svg`,
			label: "Help",
			link: SITE_URLS.HELP,
			isVisible: true,
			id: NAV_CTA_IDS.help,
		},
		{
			icon: `${NAVBAR_ASSETS_URL}suit_case_icon.svg`,
			label: "Referral Dashboard",
			action: actions.referAndClick,
			isVisible: Boolean(getIsUserBondsInvestor()) && successfulReferrals > 0,
			id: NAV_CTA_IDS.refer_and_earn,
		},
		{
			icon: `${NAVBAR_ASSETS_URL}Logout_icon.svg`,
			label: "Log out",
			action: actions.logOut,
			isVisible: true,
			id: NAV_CTA_IDS.logout,
		},
	]
}

type NavbarItems = {
	left: NavbarItem[]
	right: NavbarItem[]
}

/**
 * @param {*} actions  : Object: function list that can be attached to appropriate navbar item which returns functions that can be attached to onClick events
 * @param {*} userName : String: user name to be shown in navigational  item.
 * @returns
 */
export const getTopNavBarItems = (
	actions: TopNavbarActions,
	userName: UserName,
	userState: User,
): NavbarItems => {
	const { isLoggedIn, referralData, kycStatusV2 } = userState || {}

	return {
		left: [
			{
				icon: WintLogoIcon,
				link: isLoggedIn ? SITE_URLS.BONDS_HOME : "/",
				isVisible: true,
				id: NAV_CTA_IDS.logo,
				isLottieIcon: false,
			},
			{
				icon: null,
				label: "Bonds",
				link: SITE_URLS.BONDS_HOME,
				isVisible: isLoggedIn,
				id: NAV_CTA_IDS.assets_tab,
			},
			{
				icon: null,
				label: "FDs",
				link: SITE_URLS.FD_HOME,
				isVisible: isLoggedIn && kycStatusV2 === "APPROVED",
				id: NAV_CTA_IDS.fd,
			},
		],
		right: [
			{
				icon: navDashboardIcon,
				label: "Portfolio",
				link: SITE_URLS.DASHBOARD_V3,
				isVisible: isLoggedIn,
				buttonType: "transparent",
				id: NAV_CTA_IDS.dashboard,
			},
			{
				icon: null,
				label: "Login/Signup",
				action: () => actions?.login(),
				isVisible: !isLoggedIn,
				buttonType: "primary",
				id: NAV_CTA_IDS.login,
			},
			{
				icon: true,
				id: "NAV_DROPDOWN",
				label: userName,
				action: () => actions?.dropdown(),
				isVisible: isLoggedIn,
				buttonType: "primary",
				dropdown: getDropdownSidebarItems(
					actions,
					referralData?.successfulReferrals,
				),
			},
		],
	}
}

/**
 * @param {*} actions  : Object: function list that can be attached to appropriate navbar item which returns functions that can be attached to onClick events
 * @returns
 */

export const getBottomNavBarItems = (
	actions: BottomBarActions,
	userState: User,
): NavbarItems => {
	const { isLoggedIn, referralData, kycStatusV2 } = userState || {}

	return {
		left: [
			{
				icon: `${NAVBAR_ASSETS_URL}document_icon.svg`,
				label: "Bonds",
				link: SITE_URLS.BONDS_HOME,
				isVisible: true,
				id: NAV_CTA_IDS.assets_tab,
			},
			{
				icon: `${NAVBAR_ASSETS_URL}fd_icon.svg`,
				label: "FDs",
				link: SITE_URLS.FD_HOME,
				isVisible: isLoggedIn && kycStatusV2 === "APPROVED",
				id: NAV_CTA_IDS.fd,
			},
			{
				icon: `${NAVBAR_ASSETS_URL}suit_case_icon.svg`,
				label: "Portfolio",
				link: SITE_URLS.DASHBOARD_V3,
				isVisible: isLoggedIn,
				id: NAV_CTA_IDS.dashboard,
			},
			{
				icon: `${NAVBAR_ASSETS_URL}more_icon.svg`,
				label: "More",
				action: actions?.sidebar,
				isVisible: isLoggedIn,
				sidebar: getDropdownSidebarItems(
					actions,
					referralData?.successfulReferrals,
				),
			},
		],
		right: [
			{
				icon: null,
				label: "Get started",
				action: actions?.login,
				isVisible: !isLoggedIn,
				id: NAV_CTA_IDS.login,
			},
		],
	}
}

export const getAppDownloadLink = (isIOSDevice: boolean): string => {
	if (isIOSDevice) {
		return DOWNLOAD_APP_CARD_LINKS.ios
	}
	return DOWNLOAD_APP_CARD_LINKS.android
}

export const shouldRenderNavComponents = (
	excludedPaths: (string | RegExp)[],
	pathname: string,
): boolean => {
	const cleanPathname = removeTrailingSlashFromURL(pathname)

	// Check if any excluded path matches the current pathname
	return excludedPaths.every(excludedPath => {
		// If the excluded path is a RegExp, test if the pathname matches
		if (
			new RegExp(excludedPath) instanceof RegExp &&
			new RegExp(excludedPath).test(cleanPathname)
		) {
			return false
		}
		// If the excluded path is a string, check if it matches directly
		if (
			typeof excludedPath === "string" &&
			cleanPathname === removeTrailingSlashFromURL(excludedPath)
		) {
			return false
		}
		return true
	})
}

export const handleReferAndEarnClick = (): void => {
	window.location.href = SITE_URLS.BONDS_REFERRAL_DASHBOARD
}
