import { useEffect } from "react";
type KeyPressOptions = {
  eventType?: "keydown" | "keyup";
  eventOptions?: EventListenerOptions;
} | undefined;
export default function useKeyPress(key: KeyboardEvent["key"], callback: (e: KeyboardEvent) => void, options: KeyPressOptions = {}) {
  const {
    eventType = "keydown",
    eventOptions
  } = options;
  useEffect(() => {
    const handler = (event: KeyboardEvent) => {
      if (event.key === key) {
        callback(event);
      }
    };
    window.addEventListener(eventType, handler, eventOptions);
    return () => {
      window.removeEventListener(eventType, handler, eventOptions);
    };
  }, [key, eventType, eventOptions, callback]);
}