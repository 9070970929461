import styled, { Interpolation, css } from "styled-components";
import { CrossIcon } from "wint-icons";
import { motion } from "framer-motion";
import { useCallback } from "react";
import useLockBodyScroll from "@common/hooks/useLockBodyScroll";
import useKeyPress from "@common/hooks/useKeyPress";
import { ModalProps } from "@wint_design_system/types/ModalTypes";
export const dropIn = {
  hidden: {
    y: "8px",
    opacity: 0,
    transition: {
      duration: 0.2,
      ease: "easeOut"
    }
  },
  visible: {
    y: "0",
    opacity: 1
  },
  exit: {
    y: "8px",
    opacity: 0,
    transition: {
      duration: 0.2,
      ease: "easeIn"
    }
  }
};
const ModalContent: React.FC<ModalProps> = ({
  show,
  onClose,
  title,
  content,
  contentWithContainer,
  roundedRadius,
  padding,
  isBottomAligned,
  width,
  maxWidth,
  maxHeight,
  minHeight,
  preventBackDropClose,
  closeOnEsc,
  closeId,
  hideCloseIcon,
  styles
}) => {
  const handleClose = useCallback(() => {
    if (closeOnEsc) {
      onClose();
    }
  }, [closeOnEsc, onClose]);
  useLockBodyScroll({
    isLocked: show
  });
  useKeyPress("Escape", handleClose);
  return <ModalWrapper isBottomAligned={isBottomAligned} show={show} id="modal" data-sentry-element="ModalWrapper" data-sentry-component="ModalContent" data-sentry-source-file="ModalContent.tsx">
			<Overlay initial={{
      opacity: 0
    }} animate={{
      opacity: 1
    }} exit={{
      opacity: 0
    }} onClick={() => {
      if (!preventBackDropClose) {
        onClose();
      }
    }} data-sentry-element="Overlay" data-sentry-source-file="ModalContent.tsx" />
			<ModalContainer variants={dropIn} initial="hidden" animate="visible" exit="exit" width={width} maxWidth={maxWidth} $maxHeight={maxHeight} $minHeight={minHeight} $styles={styles} padding={padding} roundedRadius={roundedRadius} data-sentry-element="ModalContainer" data-sentry-source-file="ModalContent.tsx">
				{title ? <Header>{title}</Header> : null}

				{hideCloseIcon ? null : <CrossButton>
						<Cross id={closeId} onClick={onClose} />
					</CrossButton>}

				{contentWithContainer || <ContentContainer>{content || null}</ContentContainer>}
			</ModalContainer>
		</ModalWrapper>;
};
export default ModalContent;
const ModalWrapper = styled.div<{
  show?: boolean;
  isBottomAligned?: boolean;
}>`
	display: ${props => props.show ? "flex" : "none"};
	justify-content: center;
	align-items: ${props => props.isBottomAligned ? "flex-end" : "center"};
	position: fixed;
	top: 0;
	left: 0;
	overflow: hidden;
	z-index: 99999999;
	height: 100vh;
	width: 100vw;

	@supports (height: 100dvh) {
		height: 100dvh;
	}
`;
const Overlay = styled(motion.div)`
	position: absolute;
	top: 0;
	left: 0;
	background-color: ${({
  theme
}) => theme.colors.bg.overlay.backdrop};
	height: 100vh;
	width: 100vw;

	@supports (height: 100dvh) {
		height: 100dvh;
	}
`;
type ModalContainerProps = {
  roundedRadius?: string;
  padding?: string;
  $maxHeight?: string;
  maxWidth?: string;
  width?: string;
  $styles?: Interpolation<React.CSSProperties>;
  $minHeight?: string;
};
const ModalContainer = styled(motion.div)<ModalContainerProps>`
	display: flex;
	flex-flow: column nowrap;
	background: ${({
  theme
}) => theme.colors.bg.section.default};
	border-radius: ${({
  roundedRadius
}) => roundedRadius || "4px"};
	z-index: 10000;
	padding: ${props => props.padding ? props.padding : "0rem"};
	margin: auto;
	max-height: ${({
  $maxHeight
}) => $maxHeight || "90vh"};
	min-height: ${({
  $minHeight
}) => $minHeight || ""};
	position: relative;
	max-width: ${({
  maxWidth
}) => maxWidth || "760px"};
	width: ${props => props.width ? props.width : "760px"};
	box-shadow: 0px 21px 30.7px 0px rgba(255, 255, 255, 0.02);

	${({
  $styles
}) => css`
		${$styles}
	`}
`;
const Header = styled.div`
	padding: 1.5rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid ${({
  theme
}) => theme.colors.stroke.divider.default};
	height: 80px;
`;
const ContentContainer = styled.div`
	padding: 1.5rem;
`;
const CrossButton = styled(motion.button).attrs({
  whileTap: {
    scale: 0.9
  }
})`
	width: 24px;
	height: 24px;
	flex-shrink: 0;
	position: absolute;
	top: 0px;
	right: -32px;
	display: flex;
	align-items: center;
	justify-content: center;
`;
const Cross = styled(CrossIcon)`
	color: ${({
  theme
}) => theme.colors.text.textInverse.primary};
`;